.app {
  background-color: #051522;
  color: #fff;
}
.headerVideo {
  position:relative;
  width: 100%;
  text-align: center;

  .video {
    width: 100%;
    height: 100%;
  }
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(50% 50% at 50% 50%, rgba(5, 21, 34, 0) 0%, #051522 100%);
  }
  .titleLogo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 3.5rem;
    @media (max-width: 576px) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .mainText {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    font-family: "Raleway";
    font-weight: 800;
    font-size: 52px;
    max-width: 960px;

    @media (max-width: 834px) {
      font-size: 36px;
      top: 35%;
    }
  
    .subtitle {
      margin: 0 auto;
      margin-top: 25px;
      max-width: 536px;
      word-wrap: break-word;
  
      font-family: "Roboto";
      font-weight: 300;
      font-size: 16px;
      line-height: 26px;
    }
  }
}

.talkButton {
  display: inline-block;
  width: 117px;
  height: 33px;
  background-color: #00FF85;
  color: #000;
  border-radius: 78px;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 19px;
  border: 0;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: #fff;
  }
  &.paddingTop {
    margin-top: 10px;
  }
  @media (max-width: 834px) {
    margin-top: 35px;
  }
}

.highlight {
  font-weight: 400;
}
.underline {
  text-decoration: underline;
  cursor: pointer;
}

.bodyContent {
  background-color: #051522;
  width: 100%;
  height: auto;
}
.introSection {
  position: relative;
  padding-top: 150px;
  @media (max-width: 834px) {
    padding-top: 100px;
  }
  @media (max-width: 576px) {
    padding-top: 50px;
  }

  .textSection {
    background-color: #051522;
    width: 100%;
    padding-bottom: 25px;
    display: flex;
    text-align: left;
    @media (max-width: 834px) {
      padding: 0 25px;
    }
    @media (max-width: 1000px) {
      display: block;
    }

    .leftText, .rightText {
      max-width: 440px;
      &.marginBottom {
        margin-bottom: 50px;
      }
      @media (max-width: 1280px) {
        max-width: 340px;
      }
      @media (max-width: 1000px) {
        max-width: none;
      }
    }
    .leftText {
      margin-left: calc(20% + 65px);
      margin-right: 100px;
      @media (max-width: 1440px) {
        margin-left: calc(15% + 65px);
      }
      @media (max-width: 1100px) {
        margin-left: calc(10% + 65px);
      }
      @media (max-width: 834px) {
        margin-left: 0;
        margin-right: 50px;
      }
    }
    .rightText {
      @media (max-width: 1000px) {
        margin-left: calc(10% + 65px);
        margin-right: 100px;
        margin-top: 50px;
      }
      @media (max-width: 834px) {
        margin-left: 0;
        margin-right: 50px;
      }
    }
    .title {
      font-family: "Raleway";
      font-weight: 800;
      font-size: 32px;
      line-height: 58px;
      @media (max-width: 834px) {
        font-size: 20px;
        line-height: 30px;
      }
      &.large {
        font-size: 24px;
      }
    }
    .content {
      font-family: "Roboto";
      font-weight: 300;
      font-size: 16px;
      line-height: 26px;
      margin: 20px 0;
    }
  }

  .bottomText {
    display: flex;
    gap: 65px;
    justify-content: flex-start;
    padding-top: 80px;
    @media (max-width: 834px) {
      padding-top: 80px;
    }
    .linePart {
      margin-top: 10px;
      min-width: 20%;
      height: 0px;
      border-bottom: solid 5px#00FF85;
      @media (max-width: 1440px) {
        min-width: 15%;
      }
      @media (max-width: 1100px) {
        min-width: 10%; 
      }
      @media (max-width: 834px) {
        min-width: 200px; 
      }
    }
    .textPart {
      font-family: "Roboto";
      font-weight: 300;
      font-size: 16px;
      line-height: 26px;
      padding-right: 75px;
      @media (max-width: 834px) {
        padding: 0 25px;
      }
    }
  }
}

.green {
  color: #00FF85;
}

.focusSection {
  width: 100%;
  height: auto;
  .title {
    text-align: center;
    margin-top: 150px;
    font-family: "Raleway";
    font-size: 48px;
    font-weight: 800;
    line-height: 56px;
    @media (max-width: 834px) {
      margin-top: 125px;
      font-size: 32px;
      line-height: 38px;
    }
    @media (max-width: 576px) {
      text-align: left;
      margin-top: 100px;
      margin-left: 25px;
      font-size: 24px;
      line-height: 28px;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 75px;
    font-family: "Roboto";
    font-size: 32px;
    line-height: 35px;
    text-align: center;
    @media (max-width: 834px) {
      font-size: 20px;
      margin-top: 50px;
    }
    @media (max-width: 576px) {
      display: block;
      font-size: 16px;
      margin-top: 30px;
    }
  
    > div {
      font-weight: bold;
      &:not(:last-child) {
        border-right: solid 1px #00FF85;
      }
      &:not(:first-child) {
        padding-left: 50px;
      }
      &:not(:last-child) {
        padding-right: 50px;
      }
    }
    @media (max-width: 834px) {
      > div {
        &:not(:first-child) {
          padding-left: 25px;
        }
        &:not(:last-child) {
          padding-right: 25px;
        }
      }
    }
    @media (max-width: 576px) {
      > div {
        margin-left: 25px;
        text-align: left;
        line-height: 35px;
        margin-bottom: 10px;
        &:not(:last-child) {
          border-right: none;
        }
        &:not(:first-child) {
          padding-left: 0;
        }
        &:not(:last-child) {
          padding-right: 0;
        }
      }
    }
    .minWidth {
      min-width: 424px;
      @media (max-width: 834px) {
        min-width: 253px;
      }
    }
  }
}

.talkSection {
  width: 100%;
  text-align: center;
  margin-top: 250px;
  position: relative;
  @media (max-width: 834px) {
    margin-top: 125px;
  }
  @media (max-width: 576px) {
    margin-top: 100px;
  }
  .title {
    font-family: "Raleway";
    font-size: 48px;
    font-weight: 800;
    line-height: 56px;
    @media (max-width: 834px) {
      font-size: 32px;
      line-height: 37px;
    }
    @media (max-width: 576px) {
      text-align: left;
      font-size: 24px;
      line-height: 28px;
      margin-left: 25px;
    }
  }
  .subTitle {
    margin-top: 25px;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 300;
    line-height: 18.75px;
    @media (max-width: 576px) {
      text-align: left;
      margin-left: 25px;
    }
  }
  .formArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 75px 0 75px;
    @media (max-width: 576px) {
      margin: 50px 25px 0 25px;
    }
    .inputLine {
      margin: 0 auto;
      text-align: center;
      width: 100%;
      max-width: 698px;
      @media (min-width: 576px) {
        display: flex;
        justify-content: space-between;
        gap: 30px;
      }
      .inputElement {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 50%;
        &.company {
          margin-bottom: 24px;
        }
        @media (max-width: 576px) {
          width: 100%;
          &:first-child {
            margin-bottom: 6px;
          }
        }
      }
    }
    .textInput {
      width: 100%;
      height: 50px;
      font-family: "Roboto";
      font-size: 16px;
      border-radius: 3px;
      border: 0;
      background-color: rgba(255, 255, 255, 0.1);;
      padding: 15px 0px 15px  21px;
      color: inherit;
      font-weight: normal;
      border: 1px solid rgba(255, 255, 255, 0.25);
      &:focus {
        border: solid 3px #00FF85;
      }
      &.err {
        border: solid 3px #FF007A;
      }
    }
    .errMessage {
      text-align: left;
      height: 14px;
      margin-top: 10px;
      margin-left: 21px;
      color: #FF007A;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
    }
    label {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      padding-left: 21px;
      margin-bottom: 5px;
    }
    .singleInput {
      width: 100%;
      margin: 0 auto;
      margin-top: 6px;
      font-family: "Roboto";
      max-width: 698px;
      .mailInput {
        display: flex;
        flex-direction: column;
        text-align: left;

        > textarea {
          background-color: rgba(255, 255, 255, 0.1);;
          color: #fff;
          height: 225px;
          padding: 24px;
          border-radius: 3px;
          border: 0;
          font-size: 16px;
          font-weight: normal;
          border: 1px solid rgba(255, 255, 255, 0.25);
          &:focus {
            border: solid 3px #00FF85;
          }
          &.err {
            border: solid 3px #FF007A;
          }
          @media (max-width: 576px) {
            padding: 19px 22px;
          }
        }
      }
    }
  }
}

.sendWrapper {
  width: 100%;
  padding-top: 6px;
  max-width: 698px;
  text-align: left;
  @media (max-width: 834px) {
    padding-top: 16px;
  }
  .hint {
    line-height: 19px;
    font-weight: 300;
    .green {
      font-weight: bold;
    }
  }
  .sendButton {
    width: 156px;
    height: 39px;
    margin-top: 20px;
    border-radius: 78px;
    border: 0;
    background-color: #00FF85;
    color: #000;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    cursor: pointer;
    &:hover {
      background-color: #fff;
    }
    &.disabled {
      cursor: not-allowed;
      margin-top: 30px;
    }
  }
}

.agreeText {
  width: 100%;
  max-width: 698px;
  margin-top: 30px;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  color: #b5b9be;
  text-align: left;
}

.footerImage {
  position: relative;
  width: 100%;
  height: 526px;
  background: url('../../assets/footer-img.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 834px) {
    height: 304px;
  }
  @media (max-width: 576px) {
    height: 142px;
  }
  .mask {
    position: absolute;
    width: 100%;
    height: 286px;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #051522 0%, rgba(5, 21, 34, 0) 100%);
    @media (max-width: 834px) {
      height: 165px;
    }
    @media (max-width: 576px) {
      height: 77px;
    }
  }
}

.footer {
  padding-top: 90px;
  width: 100%;
  background-color: #0B2335;
  @media (max-width: 834px) {
    padding-top: 100px;
  }
  .compnayIntro {
    display: flex;
    gap: 55px;
    margin: 0 75px 76px 15%;
    font-family: "Raleway";
    font-size: 24px;
    font-weight: 700;
    @media (max-width: 834px) {
      display: block;
      margin: 0 75px 50px 75px;
    }
    @media (max-width: 576px) {
      margin: 0 25px 50px 25px;
    }

    .contentWrapper {
      margin-top: 6px;
      @media (max-width: 834px) {
        margin-top: 58px;
      }
      @media (max-width: 576px) {
        margin-top: 40px;
      }
      .title {
        font-weight: 800;
      }
    }
    .content {
      margin-top: 32px;
      max-width: 614px;
      word-wrap: break-word;
      font-family: "Roboto";
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      @media (max-width: 834px) {
        margin-top: 25px;
      }
    }
  }

  .copyright {
    padding: 25px 0;
    margin: 0 75px 0px 15%;
    text-align: left;
    font-family: "Roboto";
    font-weight: 300;
    font-size: 12px;
    line-height: 26px;
    @media (max-width: 834px) {
      text-align: left;
      margin-left: 75px;
    }
    @media (max-width: 576px) {
      text-align: left;
      margin-left: 25px;
    }
  }
}

.groupline {
  position: absolute;
  top: 80%;
  left: 102px;
}

.seperator {
  border-top: solid 1px rgba(255, 255, 255, 0.2);
}

.modalMask {
  width: 100VW;
  height: 100VH;
  background-color: #435059;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.popcontainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 85%;
}

.popup {
  width: 100%;
  margin: 0 auto;
  background-color: #f9f9f9;
  color: #000;
  
  text-align: left;
  border-radius: 5px;
  overflow-y: hidden;

  max-height: 80%;
  padding-bottom: 50px;

  .poptitle {
    font-family: "Raleway";
    font-weight: 800;
    font-size: 32px;
    z-index: 10;
    margin: 30px 50px 30px 50px;
    @media (max-width: 834px) {
      margin: 30px 20px;
      font-size: 24px;
    }
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  .popseperator {
    border-top: solid 1px #c4c4c4;
    width: 95%;
    margin: 0 auto;
  }

  .popcontentWrapper {
    height: 500px;
    @media (max-width: 834px) {
      height: 350px;
    }
    overflow-y: scroll;
    overflow-x: hidden;
    /**
    * Scrolling bar style.
    */
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #00FF85;
      border-radius: 10px;
    }
  }
  .popcontent {
    margin: 0 auto;
    margin-top: 42px;
    padding-right: 35px;
    
    width: 90%;
    max-height: 600px;

    font-size: 14px;    
    text-align: left;
    overflow-wrap: break-word;
    word-wrap: break-word;
    
    pre {
      white-space: pre-wrap;       /* Since CSS 2.1 */
      white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
      white-space: -pre-wrap;      /* Opera 4-6 */
      white-space: -o-pre-wrap;    /* Opera 7 */
      word-wrap: break-word;
    }
  }
}

.popcloseWrapper {
  position: absolute;
  top: -30px;
  right: 15px;
  width: 100%;
  text-align: right;
  color: white;

  .popclose {
    font-size: 14px;
    cursor: pointer;

    :first-child {
      margin-right: 10px;
    }
  }
}

.scrollInvisible {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

